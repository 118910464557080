const config = {
    // http: 'http://local-api.kyhc.top/',          // 本地环境服务
    // http: 'https://test-api.kyhc.top',
    http: 'https://admin-api.duimixinyifu.com/',
    // http: 'http://test.payment-api.com/',
    storageComponent: true, // 是否本地存储component
    storagePath: true // 是否本地存储路由路径
}

export default config
