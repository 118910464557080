import { createApp } from 'vue'
import App from './App.vue'
import router from './router/route.js'
import api from './request/api.js'
import config from './configs/appConfig'
import axios from 'axios'
import ElementPlus from 'element-plus'

import 'element-plus/dist/index.css'
import './assets/css/iconfont.css'
import "./assets/iconfont/iconfont.css"



const app = createApp(App)


app.config.globalProperties.$api = api
app.config.globalProperties.config = config

app.use(router)
app.use(ElementPlus)

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

app.mount('#app')
