const token = {
    get: function () {
        return JSON.parse(sessionStorage.getItem('token'))
    },

    /**
     * 检查是否登录，是否登录过期
     */
    check: function (router) {
        const token = this.get()
        if (! token || Number(new Date().getTime()) > (Number(token.begin_time) + Number(token.expires_in))) {
            router.push({path: '/login'})
        }
    },

    /**
     * 更新过期时间
     */
    updateExpiresIn: function () {
        const token = this.get()
        if (token) {
            token.expires_in = Number(token.expires_in) + 3600000
            sessionStorage.setItem('token', JSON.stringify(token));
        }
    }
}

export default token
